const imgData_2024 = {
  siblings: {
    title: 'Siblings',
    imgs: {
      full: {
        src: require('./siblings_2024_web.jpg'),
        width: 1500,
        height: 1061,
      },
      thumb: {
        src: require('./siblings_2024_thumb.jpg'),
        width: 600,
        height: 424,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 148 × 210 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'Commissioned painting for Christmas. The two birds represents two siblings, one of them who sadly passed away, but in another life they fly together.',
  },
  raven_portrait: {
    title: 'Raven portrait',
    imgs: {
      full: {
        src: require('./raven_portrait_2024_web.jpg'),
        width: 1061,
        height: 1500,
      },
      thumb: {
        src: require('./raven_portrait_2024_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2024',
    description: 'Charcoal and graphite on paper. 148 x 210 mm, 2024.',
    price: 96,
    original: 'MSG',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'As a monthly challenge of an art subscription box I made this raven portrait with tools I rarely use, charcoal and graphite sticks.',
  },
  loon_logo: {
    title: 'Loon logo',
    imgs: {
      full: {
        src: require('./loon_logo_2024_web.jpg'),
        width: 1500,
        height: 1385,
      },
      thumb: {
        src: require('./loon_logo_2024_thumb.jpg'),
        width: 600,
        height: 554,
      },
    },
    year: '2024',
    description: 'Pen on paper. 210 x 297 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story: 'A logo featuring loons, commissioned for printing.',
  },
  no_catch_today: {
    title: 'No Catch Today',
    imgs: {
      full: {
        src: require('./no_catch_today_2024_web.jpg'),
        width: 1500,
        height: 1138,
      },
      thumb: {
        src: require('./no_catch_today_2024_thumb.jpg'),
        width: 600,
        height: 445,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 420 x 297 mm, 2024.',
    price: 146,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1762855178/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'Three brothers on the hunt for some dinner, with not much success.<br /><br />This picture is part of my plan to create more storybook illustration-like artwork.',
  },
  yellow_rose: {
    title: 'Yellow Rose',
    imgs: {
      full: {
        src: require('./yellow_rose_2024_web.jpg'),
        width: 1060,
        height: 1500,
      },
      thumb: {
        src: require('./yellow_rose_2024_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 148 × 210 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'Every flower has its meaning, but the yellow rose in this painting has a special one. This was the favourite flower of a special person, and to make cards in her memory I painted this single rose for printing.',
  },
  embrace: {
    title: 'Embrace',
    imgs: {
      full: {
        src: require('./embrace_2024_web.jpg'),
        width: 1110,
        height: 1500,
      },
      thumb: {
        src: require('./embrace_2024_thumb.jpg'),
        width: 444,
        height: 600,
      },
    },
    year: '2024',
    description: 'Ink and watercolour on paper. 297 x 420 mm, 2024.',
    price: 136,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1694656642/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'A raven and roses in an embrace, showing the beauty of the two creatures. This piece was also an experiment to try coloured inks for the different figures, resulting in more harmonious outlines.',
  },
  vahur: {
    title: 'Vahur',
    imgs: {
      full: {
        src: require('./vahur_2024_web.jpg'),
        width: 1500,
        height: 1109,
      },
      thumb: {
        src: require('./vahur_2024_thumb.jpg'),
        width: 444,
        height: 600,
      },
    },
    year: '2024',
    description: 'Ink and watercolour on paper. 148 x 210 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story: 'A practice portrait of my dog Vahur to try some new watercolours.',
  },
};

export default imgData_2024;
