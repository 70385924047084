const imgData_2023 = {
  late_into_the_night: {
    title: 'Late Into The Night',
    imgs: {
      full: {
        src: require('./late_into_the_night_2023_web.jpg'),
        width: 1500,
        height: 1061,
      },
      thumb: {
        src: require('./late_into_the_night_2023_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2023',
    description: 'Ink and watercolour on paper. 210 x 297 mm, 2023.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'An interesting commissioned piece depicting a young boy getting lost in the night sky. This was a painting made as a gift for an astronomer, specifically made using only black and white paint.',
  },
  is_that_food: {
    title: 'Is That Food?',
    imgs: {
      full: {
        src: require('./is_that_food_2023_web.jpg'),
        width: 1500,
        height: 1061,
      },
      thumb: {
        src: require('./is_that_food_2023_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2023',
    description:
      'Ink, markers, and coloured pencils on paper. 297 x 420 mm, 2023.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'A portrait of three pups being curious if you have anything edible in your hands.',
  },
  meeko: {
    title: 'Meeko',
    imgs: {
      full: {
        src: require('./meeko_2023_web.jpg'),
        width: 1500,
        height: 1061,
      },
      thumb: {
        src: require('./meeko_2023_thumb.jpg'),
        width: 600,
        height: 424,
      },
    },
    year: '2023',
    description: 'Ink and watercolour on paper. 297 x 210 mm, 2023.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'A commissioned memorial portrait of this adorable dove who was a very special little bird. I loved painting the lights on the feathers and to see how the hollow outline becomes a lively image.',
  },
  linnea_and_robin: {
    title: 'Linnea and Robin',
    imgs: {
      full: {
        src: require('./linnea_and_robin_2023_web.jpg'),
        width: 1061,
        height: 1500,
      },
      thumb: {
        src: require('./linnea_and_robin_2023_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2023',
    description: 'Ink and watercolour on paper. 297 x 420 mm, 2023.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'Commissioned painting, a special gift. In the background are the delicate Linnea flowers, and both the robin and flower has a meaning here: a bond between two people who are represented in this painting.',
  },
  yes: {
    title: 'Yes',
    imgs: {
      full: {
        src: require('./yes_2023_web.jpg'),
        width: 1061,
        height: 1500,
      },
      thumb: {
        src: require('./yes_2023_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2023',
    description: 'Ink and watercolour on paper. 297 x 420 mm, 2023.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      'A commissioned painting celebrating a wedding. The client wanted crows, so I chose the ravens because these mighty birds are a perfect fit for such a special gift since they mate for life.<br /><br />The roses this time were modeled after my own roses in the garden, so I got a little more detail than usual.',
  },
  magnificient_frigatebird: {
    title: 'Magnificient Frigatebird',
    imgs: {
      full: {
        src: require('./magnificient_frigatebird_2023_web.jpg'),
        width: 1500,
        height: 1061,
      },
      thumb: {
        src: require('./magnificient_frigatebird_2023_thumb.jpg'),
        width: 600,
        height: 424,
      },
    },
    year: '2023',
    description: 'Ink and watercolour on paper. 297 x 210 mm, 2023.',
    price: 96,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "A commissioned piece. This painting was made on an A4 sheet and shipped across the Atlantic framed in an elegant black frame.<br /><br />This was the first time I had the chance to paint this bird. It's always a nice experience to paint something new, but when your subject is such an elegant creature I find it extra rewarding!",
  },
  rook: {
    title: 'Rook',
    imgs: {
      full: {
        src: require('./rook_2023_web.jpg'),
        width: 1061,
        height: 1500,
      },
      thumb: {
        src: require('./rook_2023_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2023',
    description: 'Charcoal on paper. 148 x 210 mm, 2023.',
    price: 96,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1447617335/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'animal_watercolour_painting',
    story:
      "I took the reference photos for this guy a while ago on a really sunny day, so the shining on those feathers were perfect for charcoal! I definitely need colour in my art, but once in a while I can deal with the dramatic effect of a monochrome palette.<br /><br />I tried charcoal years ago and found it really messy with all the dust and smudges, plus I like colours. However using only one colour and tones gives a bit of drama to any picture and I like that, and if you examine my rook here you can see there's some sneaky white charcoal and pastel used as well.",
  },
};

export default imgData_2023;
