const imgData_2024 = {
  what_are_you: {
    title: 'What Are You?',
    imgs: {
      full: {
        src: require('./what_are_you_2024_web.jpg'),
        width: 1092,
        height: 1500,
      },
      thumb: {
        src: require('./what_are_you_2024_thumb.jpg'),
        width: 437,
        height: 600,
      },
    },
    year: '2024',
    description: 'Pen on paper. 250 x 349 mm, 2024.',
    price: 0,
    original: 'MSG',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story:
      'Continuing my endeavour with micron pens, I drew this illustration picturing an unexpected encounter. Three mink brothers came upon this strange unicorn-like creature, a Kirin. Kirins are the Chinese unicorns with scaled bodies like a dragon.',
  },
  stash: {
    title: 'Stash',
    imgs: {
      full: {
        src: require('./stash_2024_web.jpg'),
        width: 1061,
        height: 1500,
      },
      thumb: {
        src: require('./stash_2024_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 297 x 420 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story:
      'Stash was an adorable pup named after Velitraxistaach from the books "He Who Fights With Monsters". After he died in a tragic accident I was contacted to paint a memorial portrait of him showing the rainbow dragon features as he flies in the cosmos.',
  },
  the_librarian: {
    title: 'The Librarian',
    imgs: {
      full: {
        src: require('./the_librarian_2024_web.jpg'),
        width: 1061,
        height: 1500,
      },
      thumb: {
        src: require('./the_librarian_2024_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 297 x 420 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story:
      "It could be one of the best background stories I got for a commission that led me to the concept of this painting. Enzo, the beloved cat appeared in the owner's dream after he died. He appeared as a librarian of a very, very old dusty library. In this portrait he is taking a break from the hard work of sorting ancient books.",
  },
  a_story_of_everything: {
    title: 'A Story of Everything',
    imgs: {
      full: {
        src: require('./a_story_of_everything_2024_web.jpg'),
        width: 1500,
        height: 1061,
      },
      thumb: {
        src: require('./a_story_of_everything_2024_thumb.jpg'),
        width: 600,
        height: 424,
      },
    },
    year: '2024',
    description: 'Pen on paper. 594 x 420 mm, 2024.',
    price: 586,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1831243255/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story:
      "The longest time I've ever spent on an artwork, and the first time I made one exclusively with pens. Working with pens takes a lot more time that painting, but gives me the freedom to go into really fine details, and I also enjoy the challenge of the lack of colours - everything has to be expressed with 4 shades (black, dark grey, light grey, and white).<br /><br />This highly detailed huge drawing took about 3 months to finish, but it has everything I like to draw. Available framed.",
  },
  rellian_and_kreig: {
    title: 'Rellian and Kreig',
    imgs: {
      full: {
        src: require('./rellian_and_kreig_2024_web.jpg'),
        width: 1061,
        height: 1500,
      },
      thumb: {
        src: require('./rellian_and_kreig_2024_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 297 x 420 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story:
      'Two D&D characters commissioned. Rellian the fire mage is corrupted by Kreig, the slimy goblin travelling on his shoulder. Here they are crossing a dark bog.',
  },
  playtime: {
    title: 'Playtime',
    imgs: {
      full: {
        src: require('./playtime_2024_web.jpg'),
        width: 1500,
        height: 1061,
      },
      thumb: {
        src: require('./playtime_2024_thumb.jpg'),
        width: 600,
        height: 424,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 297 x 210 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story:
      'This is a playful couple, commissioned to celebrate a relationship.',
  },
  fairy_tattoo: {
    title: 'Fairy tattoo',
    imgs: {
      full: {
        src: require('./fairy_tattoo_2024_web.jpg'),
        width: 1061,
        height: 1500,
      },
      thumb: {
        src: require('./fairy_tattoo_2024_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 148 x 210 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story: 'Commissioned tattoo design in colour.',
  },
  the_grandmother: {
    title: 'The Grandmother',
    imgs: {
      full: {
        src: require('./the_grandmother_2024_web.jpg'),
        width: 1061,
        height: 1500,
      },
      thumb: {
        src: require('./the_grandmother_2024_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 297 x 420 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story:
      'This painting was commissioned as a lovely gift for a grandmother who loves reading fairy tales and fantasy stories with her granddaughter.',
  },
  the_king: {
    title: 'The King',
    imgs: {
      full: {
        src: require('./the_king_2024_web.jpg'),
        width: 1061,
        height: 1500,
      },
      thumb: {
        src: require('./the_king_2024_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 297 x 420 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story:
      'We can admit all cats act like kings, but this fella got an actual portrait worthy of a monarch. In the background the stained glass features his court while he enjoys a velvet throne.',
  },
  cockatrice: {
    title: 'Cockatrice',
    imgs: {
      full: {
        src: require('./cockatrice_2024_web.jpg'),
        width: 1061,
        height: 1500,
      },
      thumb: {
        src: require('./cockatrice_2024_thumb.jpg'),
        width: 424,
        height: 600,
      },
    },
    year: '2024',
    description: 'Pen on paper. 210 x 297 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story:
      'A cockatrice is a mythical rooster-dragon creature. This commissioned custom colouring page was drawn with three heads.',
  },
  the_dragon_meets_the_stag: {
    title: 'The Dragon Meets The Stag',
    imgs: {
      full: {
        src: require('./the_dragon_meets_the_stag_2024_web.jpg'),
        width: 1125,
        height: 1500,
      },
      thumb: {
        src: require('./the_dragon_meets_the_stag_2024_thumb.jpg'),
        width: 450,
        height: 600,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 285 x 380 mm, 2024.',
    price: 0,
    original: 'AVAILABLE',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: 'https://www.etsy.com/ie/listing/1711613402/',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story:
      "I've never been much of a sketcher, but this painting came alive from a doodle I made in my notebook. And I wanted light and shine. But a dragon is lonely on its own, so he needed someone to meet - that's how the dragon met the stag.<br /><br />This painting is available framed.",
  },
  a_fishing_village: {
    title: 'A Fishing Village',
    imgs: {
      full: {
        src: require('./a_fishging_village_2024_web.jpg'),
        width: 1500,
        height: 1061,
      },
      thumb: {
        src: require('./a_fishging_village_2024_thumb.jpg'),
        width: 600,
        height: 424,
      },
    },
    year: '2024',
    description: 'Pen and watercolour on paper. 297 x 210 mm, 2024.',
    price: 0,
    original: 'SOLD',
    prints: 'NOTYET',
    printPrice: 0,
    buyUrl: '',
    ship: 'FREE_IRL',
    printUrl: '',
    discount: 0,
    discountPrint: 0,
    type: 'fantasy_watercolour_painting',
    story:
      'A commissioned painting of an ancient dragon sleeping in a fishing village. The dragon has been asleep for so long the villagers built around its body.<br /><br />I enjoyed drawing and painting small medieval styled buildings with fine details, something most of my art never displays as I like to concentrate on natural symbols.',
  },
};

export default imgData_2024;
